<template>
  <div class="standard-page py-3 text-center">
    <v-progress-circular indeterminate color="primary" />
  </div>
</template>

<script>
export default {
  name: "Dashboard",

  mounted() {
    const redirectItems = [
      {
        routeName: "InfluencerDiscovery",
        serviceName: this.constants.model.user.allowedServices.influencerDiscovery
      },
      {
        routeName: "InfluencerInsight",
        serviceName: this.constants.model.user.allowedServices.influencerInsight
      },
      {
        routeName: "PaidCollaborations",
        serviceName: this.constants.model.user.allowedServices.paidCollaborations
      }
    ]

    let shouldFallback = true

    for (const item of redirectItems) {
      if (this.$store.getters["auth/isServiceAllowed"](item.serviceName)) {
        this.$router.replace({ name: item.routeName })

        shouldFallback = false

        break
      }
    }

    // fallback by redirecting to generated reports section
    if (shouldFallback) {
      this.$router.replace({ name: "Reports" })
    }
  }
}
</script>
